import React from 'react';


class Logo extends React.PureComponent {

  render() {

    const {
      logoFill
    } = this.props;

    return (
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296 96" width="78" height="24">
        <g>
          <rect fill={logoFill} x="113" width="26" height="96"/>
          <polygon fill={logoFill} points="64 96 64 61 26 61 26 96 0 96 0 0 26 0 26 37.73 64 37.73 64 0 90 0 90 96 64 96"/>
          <rect fill={logoFill} x="270" width="26" height="96"/>
          <path fill={logoFill} d="M205,96a48,48,0,1,1,48-48A48.05,48.05,0,0,1,205,96Zm0-75c-13.32,0-25,12.62-25,27s11.68,27,25,27,25-12.62,25-27S218.32,21,205,21Z"/>
        </g>
      </svg>

      );
  }
}

export default Logo;
