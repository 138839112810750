import styled, { keyframes } from 'styled-components';

import { BasicParagraphTypo } from '../../commonStyles/typoTheme';

export const keyframesTaskSlide = keyframes`
  0% {
    margin-left: 0;
    opacity: 1;
  }
  50% {
    margin-left: 100%;
    opacity: 0;
  }
  90% {
    margin-left: 0;
    opacity: 1;
  }
`

export const TaskAnimationContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  left: 0;
  float: left;
  line-height: 5rem;
  background: transparent;
  margin: 3rem 0;

  /* 1280px */
    @media (min-width: 80em) {
      width: calc(60% - 4rem);
      margin: 0 2rem;
    }
`;


export const TaskAnimationTile = styled.div`
  display: block;
  position: relative;
  width: 90%;
  margin: auto;
  max-width: 50rem;
  height: 8rem;
  overflow: hidden;
  background: ${ state => state.tileActive ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.5)' };
  border-radius: 1rem;
  box-shadow: 0 0 50px rgba(117, 119, 134, 0.15);
  padding: 0 4rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 200ms;
  
  ${BasicParagraphTypo} {
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 1rem;
    text-overflow: ellipsis;
    @media (min-width: 48em) {
      max-width: 18rem;
    }
  }
`;


export const TaskAnimationDot = styled.div`
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 3rem;
  border: ${ state => state.tileActive ? '2px solid' : '0.5rem solid' };
  border-color: ${ props => props.dotColor ? props.dotColor : 'yellow' };
  top: 3.5rem;
  float: left;
  margin-right: 2rem;
  transition: 200ms;
`;

export const TaskAnimationTitle = styled.div`
  display: inline-block;
  position: relative;
  width: fit-content;
  float: left;
  margin-top: 2.2rem;
  opacity: ${ state => state.tileActive ? '1' : '0.4' };
`;

export const TaskAnimationHelper = styled.div`
  display: none;
  position: relative;
  width: fit-content;
  padding: 0 1.5rem;
  background: #20db81;
  line-height: 3rem;
  height: 3rem;
  margin-right: ${ state => state.tileHovered ? '-2rem' : '-24rem' };
  margin-left: 2rem;
  margin-top: 2.5rem;
  float: right;
  border-radius: 0.5rem;
  opacity: ${ state => state.tileHovered ? '1' : '0' };
  z-index: 300;
  transition: 300ms;
  color: #ffffff;

  /* 1280px */
    @media (min-width: 80em) {
      display: inline-block;
    }
`;

export const TaskAnimationCurrentState = styled.div`
  display: inline-block;
  position: absolute;
  width: fit-content;
  padding: 0 1rem;
  background: ${ state => state.tileActive ? '#5c91ff' : '#20db81' };
  height: 3rem;
  right: 2rem;
  border-radius: 3rem;
  line-height: 3rem;
  color: #ffffff;
  margin-top: 2.5rem;
  margin-left: 2rem;
  transition: 300ms;

  /* 1280px */
    @media (min-width: 80em) {
      float: left;
    }
`;

export const TaskAnimationDate = styled.div`
  display: none;
  position: relative;
  width: 30%;
  margin-top: 2.2rem;
  text-align: right;
  opacity: ${ state => state.tileActive ? '0.5' : '0.2' };
  white-space: nowrap;

  /* 1280px */
    @media (min-width: 80em) {
      display: inline-block;
    }
`;
