import React from 'react';

import { SmallSubtitlesTypo, LinkTypo } from '../commonStyles/typoTheme';
import { FixedTopLogo, FixedTopContact, FixedScrollDown } from '../commonStyles/FixedTopMenuStyles';

import Logo from './Logo';


class FixedTopMenu extends React.PureComponent {
  render() {


    const {
      marginSize,
      logoFill
    } = this.props;
    
    return (
      <>
        <FixedTopLogo>
          <Logo logoFill={logoFill}/>
        </FixedTopLogo>
        <FixedScrollDown marginSize={marginSize}>
          <SmallSubtitlesTypo>
            scroll down →
          </SmallSubtitlesTypo>
        </FixedScrollDown>
        <FixedTopContact marginSize={marginSize}>
          <SmallSubtitlesTypo>
            contact us<br />
            <LinkTypo color={logoFill} href={'mailto:contact@hioiapp.com'}>
              contact@hioiapp.com
            </LinkTypo>
          </SmallSubtitlesTypo>
        </FixedTopContact>
      </>

    );
  }
}

export default FixedTopMenu;
