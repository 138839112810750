import styled from "styled-components";

export const FeatureDescriptionTextContainer = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;
  top: 0;
  margin: 5rem 0;

  /* 1366px */
  @media (min-width: 85.375em) {
    width: 40%;
    padding-right: ${ props => props.isIcons ? '8rem' : '0' };
    margin: 0;
  }

  `;


export const FeatureDescriptionTextSubHeader = styled.div`
  margin: 0 0 5rem 0;
  color: #7683ff;
`;

export const FeatureDescriptionTextHeader = styled.div`
  margin-bottom: 2rem;
`;

export const FeatureDescriptionTextParagraph = styled.div`
  margin-bottom: 2rem;
`;

export const FeatureDescriptionTextTip = styled.div`
  display: none;
  margin: 9rem 0 0 0;
  opacity: 0.7;
  background: rgba(203, 217, 250, 0.64);
  border-radius: 2rem;
  height: 4rem;
  line-height: 4rem;
  padding: 0 2rem;
  width: fit-content;

  /* 1366px */
  @media (min-width: 85.375em) {
    display: ${ props => props.hasTip ? 'block' : 'none' };
  }
`;
