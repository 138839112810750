import React from 'react';

import { SectionFeatureStyle } from '../commonStyles/SectionsStyle';

import FeatureDescriptionText from './FeatureDescriptionText';

import TimelineAnimation from './TimelineAnimation';
import TimelinesArray from './TimelinesArray';

class SectionFeatureTimelineAnim extends React.PureComponent {

  render() {
    const {
      subheader,
      header,
      paragraph,
      isIcons,
      tip
    } = this.props;

    return (
      <SectionFeatureStyle isIcons={isIcons}>
        {
          TimelinesArray.map((timelinesArrayItem, i) => {
            return (
              <TimelineAnimation
                item={timelinesArrayItem}
                key={'TimelineAnimation-'+i}
              />
            )
          })
        }
        <FeatureDescriptionText
          isIcons={false}
          subheader={subheader}
          header={header}
          paragraph={paragraph}
          hasTip={true}
          tip={tip}
        />
      </SectionFeatureStyle>
    );
  }
}

export default SectionFeatureTimelineAnim;
