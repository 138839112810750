import React from 'react';

import setMaxVal from './helpers/setMaxVal';
import getTop from './helpers/getTop';
import getInitialValue from './helpers/getInitialValue';
    
class ObjectImage extends React.PureComponent {
    constructor(props) {
      super();

      this.state = {
        top: '',
      };

    }


  static getDerivedStateFromProps(props, state) {

    const windowPosition = parseInt(props.windowPosition);
    
    const maxScroll = props.maxScroll === state.maxScroll  ? state.maxScroll : props.maxScroll;
    
    const initialValue = getInitialValue(props);
    const maxVal = props.maxScroll != state.maxScroll ||  props.innerWidth != state.newWindowSize  ? setMaxVal(props, initialValue) : state.maxVal;

    const top = getTop(props, initialValue);

    const initialTop = props.innerWidth != state.newWindowSize ? top : state.initialTop;

    if (props.maxScroll === 1) return null;

    if (!state.top) return {
      top: top,
      initialTop: top,
      windowPosition: windowPosition,
      maxVal: maxVal,
      initialValue: initialValue,
      maxScroll: maxScroll,
      newWindowSize: props.innerWidth
    }

    const newPosition = props.fromBottom ? parseInt(initialTop - windowPosition * 2.2) : parseInt(initialTop + windowPosition * 2.2);
    
    if (!props.fromBottom && newPosition >= maxVal || !props.fromBottom && top >= maxVal && windowPosition < state.windowPosition || props.fromBottom && newPosition <= maxVal || props.fromBottom && top <= maxVal && windowPosition > state.windowPosition) {
      return {
        top: maxVal,
        initialTop: initialTop,
        maxVal: maxVal,
        initialValue: initialValue,
        maxScroll: maxScroll,
        newWindowSize: props.innerWidth
      }
    }
    
    return {
      top: newPosition,
      initialTop: initialTop,
      windowPosition: windowPosition,
      maxVal: maxVal,
      initialValue: initialValue,
      maxScroll: maxScroll,
      newWindowSize: props.innerWidth
    }

  }

  render() {

    
    const { posLeft, zIndex } = this.props;
    const { top } = this.state;

    return (
      <div
        style={
          {
            top : top+'px',
            display: 'block',
            position: 'absolute',
            width: '18%',
            opacity: 1,
            left: posLeft,
            backgroundPosition: 'center',
            willChange: 'top',
            transform: 'translateZ(0)',
            transition: 'top 300ms ease-out',
            zIndex: zIndex ? zIndex : 0
          }
        }
      >
        <img src={this.props.src} width='100%' alt='' />
      </div>
    );
  }
}

export default ObjectImage;
