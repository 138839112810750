import styled from 'styled-components';

export const TimelineAnimationContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  left: 0;
  float: left;
  line-height: 5rem;
  background: transparent;
  margin: 3rem 0;

  /* 1280px */
    @media (min-width: 80em) {
      width: calc(60% - 4rem);
      margin: 0 2rem;
    }
`;

export const TimelineAnimationBar = styled.div`
  display: block;
  position: relative;
  width: ${ props => props.timelineBarLength }% ;
  margin-left:  ${ props => props.barStart }% ;
  margin-bottom: 2rem;
  height: 4rem;
  background: ${ props => props.bgColor };
  border-radius: 2.5rem;
  z-index: 200;
`;


export const TimelineAnimationDot = styled.div`
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 2.5rem;
  margin-left: ${ props => props.event1Percentage };
  float: left;
  background: ${ props => props.dotColor };
  z-index: 2;
`;

export const TimelineAnimationStartDot = styled.div`
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 2.5rem;
  float: left;
  background: ${ props => props.dotColor };
`;

export const TimelineAnimationEndDot = styled.div`
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 2.5rem;
  float: right;
  background: ${ props => props.dotColor };
`;

export const TimelineEventInfo = styled.div`
  display: none;
  position: absolute;
  left: ${ props => props.event1Percentage };
  bottom: 5rem;
  min-width: 25rem;
  border-radius: 1rem;
  float: right;
  background: #ffffff;
  box-shadow: 0 0 50px rgba(117, 119, 134, 0.15);
  transition: 200ms;
  opacity: ${ state => state.dotHovered ? '1' : '0' };
  overflow: hidden;

  /* 1366px */
  @media (min-width: 48em) {
    display: block;
  }
`;

export const TimelineEventInfoTop = styled.div`
  display: block;
  position: relative;
  padding: 3rem;
  width: 100%;
  background: ${ props => props.dotColor };
  color: rgba(255, 255, 255, 0.85);
`;

export const TimelineEventInfoBottom = styled.div`
  display: block;
  position: relative;
  padding: 3rem;
  width: 100%;
`;

export const TimelineEventInfoDate = styled.div`
  display: block;
  position: relative;
`;

export const TimelineEventInfoNotification = styled.div`
  display: block;
  position: relative;
`;

export const TimelineEventInfoPeople = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 4rem;
  margin-top: 4rem;
`;

export const TimelineEventInfoAvatar = styled.div`
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  margin-right: 0.5rem;
  overflow: hidden;
`;

export const TimelineEventInfoAvatarBg = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${ props => props.avatarColor };
  opacity: 0.3;
  z-index: 1;
`;

export const TimelineEventInfoAvatarLetter = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 4rem;
  text-align: center;
  color: ${ props => props.avatarColor };
  z-index: 2;
`;
