import React from 'react';

import throttle from '../helpers/throttle';

import MainContainer from '../commonStyles/MainContainer';

import SEO from './seo';
import SectionHeader from './SectionHeader';
import SectionMeetHioi from './SectionMeetHioi';
import SectionFeatureTaskAnim from './SectionFeatureTaskAnim';
import SectionFeatureTimelineAnim from './SectionFeatureTimelineAnim';
import SectionIcons from './SectionIcons';
import SectionSubscribe from './SectionSubscribe';
import SectionFooter from './SectionFooter';
import FixedTopMenu from './FixedTopMenu';
import BackgroundObjects from './BackgroundObjects';

import UpcomingTasksVideo from '../images/videos/tasklist.mp4';



class IndexComponent extends React.PureComponent {
  constructor() {
    super();
    this.refMaxScroll = React.createRef();
    this.showOnScroll = this.showOnScroll.bind(this);
    this.setBackground = this.setBackground.bind(this);
    this.setRefMaxScroll = this.setRefMaxScroll.bind(this);
    this.setResizeWidth = this.setResizeWidth.bind(this);

    this.state = {
      windowPosition: 1,
      innerHeight: 768,
      innerWidth: 1024,
      background: '#FBFBFF',
      maxScroll: 1024,
      logoColor: '#FFFFFF'
    }
  }

  setResizeWidth() {
    this.setState({
      innerWidth: window.innerWidth
    })
  }
  setRefMaxScroll() {
    if (this.refMaxScroll.current) this.setState({
      maxScroll: parseInt(this.refMaxScroll.current.getBoundingClientRect().top  + document.documentElement.scrollTop),
      windowPosition: window.pageYOffset,
      innerHeight: window.innerHeight
    })
  }

  setBackground(colors) {
    this.setState({
      background: colors.background,
      logoColor: colors.logoColor
    });
  }


  showOnScroll() {
    const windowPosition = window.pageYOffset;
    if (windowPosition !== this.state.windowPosition) this.setState({
      windowPosition: window.pageYOffset,
    });
  }

  componentDidMount() {

    // fix for static build - needs to be set when window is avaible eg. browser.
    this.setState({
      marginSize: window.innerWidth,
      windowPosition: window.pageYOffset,
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });

    this.setRefMaxScroll();
    window.addEventListener('resize', throttle(this.setRefMaxScroll, 10));
    window.addEventListener('resize', throttle(this.setResizeWidth, 10));
    window.addEventListener('scroll', throttle(this.showOnScroll, 5));
  };

  componentWillUnmount() {
    window.removeEventListener('resize', throttle(this.setRefMaxScroll, 30));
    window.removeEventListener('scroll', throttle(this.showOnScroll, 10));
  };



  render() {

    const {
      windowPosition,
      innerHeight,
      background,
      maxScroll,
      innerWidth,
      maxScrollCurrentTop,
      logoColor
    } = this.state;

    return (
      <div style={
          {
            'background': background,
            'transition': 'background 400ms linear',
            'fontSize': 0
          }
        }
      >
        <SEO title='Home' />
        <FixedTopMenu marginSize={innerWidth} logoFill={logoColor}/>
        <BackgroundObjects windowPosition={windowPosition} innerHeight={innerHeight} maxScrollCurrentTop={maxScrollCurrentTop} maxScroll={maxScroll} innerWidth={innerWidth} />
        <MainContainer marginSize={innerWidth}>
          <SectionHeader />
          <SectionMeetHioi windowPosition={windowPosition} setBackground={this.setBackground} refMaxScroll={this.refMaxScroll} />
          <SectionFeatureTaskAnim
            videoSrc={UpcomingTasksVideo}
            subheader={'important tasks'}
            header={'Stay in control'}
            paragraph={'Manage your daily tasks with our simple to-do list. Decide what tasks have to be done today, set due dates and stay focused on what is most important.'}
            tip={'tap the tiles to mark as done!'}
          />
          <SectionIcons
            subheader={'Customizable software'}
            header={'Tool for everyone'}
            paragraph={'Hioi is flexible and versalite -that means it can be used by large marketing teams, small design agencies, creative freelancers and even busy stay-at-home mums and dads.'}
            tip={''}
          />
          <SectionFeatureTimelineAnim
            windowPosition={windowPosition}
            subheader={'beautiful and simple'}
            header={'All your projects in one place'}
            paragraph={'Need an easy, visual way to keep track on all milestones and deadlines? Track time, mark important tasks and keep an eye on your week with HIOI beautiful and simple timeline'}
            tip={'hover over the timeline to see the event details!'}
          />
          <SectionSubscribe />
          <SectionFooter />
        </MainContainer>
      </div>
    );
  }
}

export default IndexComponent;
