import React from 'react';

import { LinkTypo } from '../commonStyles/typoTheme';
import { SectionFooterStyle } from '../commonStyles/SectionsStyle';
import { FooterColumn, FooterLink } from './styles/FooterStyles';

class SectionFooter extends React.PureComponent {
  render() {

    const linksFirstCol = [
      {
        title: 'contact@hioiapp.com',
        url: 'mailto:contact@hioiapp.com'
      },
      {
        title: 'facebook',
        url: 'https://www.facebook.com/hioiapp/'
      }
    ];

    const linksSecondCol = [
      {
        title: 'HIOI 2019',
        url: 'https://hioiapp.com/'
      }
    ];

    const listItemsFirstCol = linksFirstCol.map((link) =>
      <FooterLink key={link.title} href={link.url}>
        {link.title}
      </FooterLink>
    );

    const listItemsSecondCol = linksSecondCol.map((link) =>
      <FooterLink key={link.title} href={link.url} isLast={true}>
        {link.title}
      </FooterLink>
    );


    return (
      <SectionFooterStyle>
        <FooterColumn>
          <LinkTypo>
            <ul>{listItemsFirstCol}</ul>
          </LinkTypo>
        </FooterColumn>
        <FooterColumn isLast={true}>
          <LinkTypo>
            <ul>
              {listItemsSecondCol}
            </ul>
          </LinkTypo>
        </FooterColumn>
      </SectionFooterStyle>
    );
  }
}

export default SectionFooter;
