import React from 'react';

import { BasicParagraphTypo, ProgressIndicatorTypo } from '../commonStyles/typoTheme';
import {
  TaskAnimationTile,
  TaskAnimationDot,
  TaskAnimationTitle,
  TaskAnimationCurrentState,
  TaskAnimationHelper,
  TaskAnimationDate
} from './styles/TaskAnimationStyles';

class TaskAnimation extends React.PureComponent {
  constructor() {
    super();
    this.setTile = this.setTile.bind(this);
    this.hoverTile = this.hoverTile.bind(this);
    this.unHoverTile = this.unHoverTile.bind(this);
    this.state = {
      tileActive: true,
      progressText: 'in progress',
      tileHovered: false,
    }
  }

  setTile() {
    if (this.state.tileActive === true ) {
    this.setState({
      tileActive: false,
      progressText: 'done'
    })
    } else {
      this.setState({
        tileActive: true,
        progressText: 'in progress'
      })
    }
  }

  hoverTile() {
    if (this.state.tileActive === true ) {
    this.setState({
      tileHovered: true
    })
    } else {
      this.setState({
        tileHovered: false
      })
    }
  }

  unHoverTile() {
    this.setState({
      tileHovered: false
    })
  }



  render() {
    const {
      dotColor,
      taskTitle,
      taskDate
    } = this.props;

    const {
      tileActive,
      progressText,
      tileHovered
    } = this.state;

    return (
      <TaskAnimationTile onClick={this.setTile} onMouseOver={this.hoverTile} onMouseOut={this.unHoverTile} tileActive={tileActive}>
        <TaskAnimationDot dotColor={dotColor} tileActive={tileActive}>
        </TaskAnimationDot>
        <TaskAnimationTitle tileActive={tileActive}>
          <BasicParagraphTypo>
            {taskTitle}
          </BasicParagraphTypo>
        </TaskAnimationTitle>
        <TaskAnimationCurrentState tileActive={tileActive}>
          <ProgressIndicatorTypo>
            {progressText}
          </ProgressIndicatorTypo>
        </TaskAnimationCurrentState>
        <TaskAnimationHelper tileHovered={tileHovered}>
          <ProgressIndicatorTypo>
            Mark as done
          </ProgressIndicatorTypo>
        </TaskAnimationHelper>
        <TaskAnimationDate>
          <BasicParagraphTypo>
            {taskDate}
          </BasicParagraphTypo>
        </TaskAnimationDate>
      </TaskAnimationTile>
    );
  }
}

export default TaskAnimation;
