import React from 'react';


class IconLocation extends React.PureComponent {

  render() {

    return (
      <svg version="1.1" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22" width="26px" height="26px">
        <path fill ='#bdbfc6' d="M8,2A4,4,0,0,0,4,6c0,2,1.71,8,4,8s4-6,4-8A4,4,0,0,0,8,2ZM8,8a2,2,0,1,1,2-2A2,2,0,0,1,8,8Z" transform="translate(-4 8)"/>
      </svg>
      );
  }
}

export default IconLocation;
