import styled from "styled-components";

export const SubscribeEmail = styled.form`
  display: inline-block;
  position: relative;
  width: 100%;
  left: 0;
  float: left;
  line-height: 5rem;
  overflow: hidden;

  /* 1024px */
    @media (min-width: 64em) {
      display: block;
      height: 5rem;
    }
`;


export const SubscribeInputField = styled.input`
  display: block;
  position: relative;
  width: 100%;
  height: 5rem;
  border-radius: 3rem;
  border: 1px solid #ffffff;
  text-align: center;
  background: ${ props => props.hasBackground ? 'rgb(59, 78, 214)' : 'transparent' };
  margin-bottom: 2rem;
  float: left;
  font-size: 1.8rem;
  font-weight: 400;
  color: #ffffff;
  font-family: 'Jost',sans-serif;

  &:focus {
    outline: none;
  }

  /* 1024px */
    @media (min-width: 64em) {
      display: block;
      position: absolute;
      right:0;
      width: 100%;
      text-align: left;
      padding-left: 2rem;
    }

`;

export const SubscribeButton = styled.button`
  display: block;
  position: relative;
  width: 100%;
  height: 5rem;
  background: #ffffff;
  border: 1px solid #ffffff;
  color: blue;
  border-radius: 3rem;
  text-align: center;
  float: left;
  font-size: 1.8rem;
  font-weight: 400;

  /* 1024px */
    @media (min-width: 64em) {
      display: block;
      position: absolute;
      right:0;
      width: fit-content;
      padding: 0 3rem;
    }
`;
