import React from 'react';

import { SectionMeetHioiStyle } from '../commonStyles/SectionsStyle';

import { MeetHioiBg } from './styles/MeetHioiStyles';

import MeetHioiImage from './MeetHioiImage';
import MeetHioiText from './MeetHioiText';

class SectionMeetHioi extends React.PureComponent {

  render() {

    const {
      refMaxScroll,
      setBackground,
      windowPosition
    } = this.props;

    return (
      <SectionMeetHioiStyle>
        <MeetHioiBg />
        <MeetHioiText refMaxScroll={refMaxScroll} windowPosition={windowPosition} setBackground={setBackground} />
        <MeetHioiImage windowPosition={windowPosition} />
      </SectionMeetHioiStyle>
    );
  }
}

export default SectionMeetHioi;
