import React from 'react';

import throttle from '../helpers/throttle';

import {
  TimelineAnimationContainer,
  TimelineAnimationBar,
  TimelineAnimationDot
} from './styles/TimelineAnimationStyles';

import TimelineEventInfoPopup from './TimelineEventInfoPopup';




class TimelineAnimation extends React.PureComponent {
  constructor() {
    super();
    
    this.hoverDot = this.hoverDot.bind(this);
    this.renderTimelineBars = this.renderTimelineBars.bind(this);
    
    this.state = {
      dotHovered: false,
    }
  }

  hoverDot() {
    this.setState((state) => {      
      return {
        dotHovered: state.dotHovered ? false : true
      }
    })
  }




  renderTimelineBars(timelineBar) {
    const {
      dotHovered
    } = this.state;

    const timelineBarLength = timelineBar.barEnd - timelineBar.barStart;
    return (
      <TimelineAnimationBar
        key={timelineBar.id}
        barStart={timelineBar.barStart}
        timelineBarLength={timelineBarLength}
        bgColor={timelineBar.color}
      >
        <TimelineAnimationDot
          onMouseOver={ this.hoverDot}
          onMouseOut={this.hoverDot}
          event1Percentage={timelineBar.event1Percentage}
          dotColor={timelineBar.dotColor}
        >
        </TimelineAnimationDot>
        <TimelineEventInfoPopup
          dotHovered={dotHovered}
          dotColor={timelineBar.dotColor}
          event1Name={timelineBar.event1Name}
          event1Date={timelineBar.event1Date}
          event1Location={timelineBar.event1Location}
          event1Notification={timelineBar.event1Notification}
          avatar1Color={timelineBar.event1Avatar1Color}
          avatar1Letter={timelineBar.event1Avatar1Letter}
          avatar2Color={timelineBar.event1Avatar2Color}
          avatar2Letter={timelineBar.event1Avatar2Letter}
          event1Percentage={timelineBar.event1Percentage}
        / >
      </TimelineAnimationBar>
    );
  }

  render() {
    
    const {
      item
    } = this.props;
    
    if (!item) return '';

    return (
      <TimelineAnimationContainer>
        {this.renderTimelineBars(item)}
      </TimelineAnimationContainer>
    );
  }
}

export default TimelineAnimation;
