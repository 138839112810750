import React from 'react';

import { SectionIconsStyle } from '../commonStyles/SectionsStyle';

import Brush from '../images/icon_brush.svg';
import Code from '../images/icon_code.svg';
import Holiday from '../images/icon_holiday.svg';
import Marketing from '../images/icon_marketing.svg';

import {
  IconsGrid
 } from './styles/IconsGridStyles';

import FeatureDescriptionText from './FeatureDescriptionText';
import IconBox from './IconBox';


class SectionIcons extends React.PureComponent {
  render() {
    const {
      subheader,
      header,
      paragraph
    } = this.props;

    return (
      <SectionIconsStyle>
        <FeatureDescriptionText
          isIcons={true}
          subheader={subheader}
          header={header}
          paragraph={paragraph}
          hasTip={false}
        />
        <IconsGrid>
        <IconBox
          imgSrc={Brush}
          iconColor={'#dee1fc'}
          iconHeader={'Creative agencies'}
          iconParagraph={'Track your clients, proposals and assets. Assign the tasks and plan the product launch. All in one place.'}
          isLeft={true}
        />
        <IconBox
          imgSrc={Code}
          iconColor={'#D2F9E5'}
          iconHeader={'Development'}
          iconParagraph={'Use Hioi, an easy and intuitive tool to create your roadmap, plan user research and track bugs.'}
        />
        <IconBox
          imgSrc={Marketing}
          iconColor={'#ffe0be'}
          iconHeader={'Marketing'}
          iconParagraph={'See all of your marketing campaigns on a beautiful timeline. List tasks, assign them and track the progress.'}
          isLeft={true}
        />
        <IconBox
          imgSrc={Holiday}
          iconColor={'#FFCBC7'}
          iconHeader={'Everyday life'}
          iconParagraph={'Hioi really is designed for everyone - use it to plan your weekend break, buy a car or find a perfect apartment.'}
        />
        </IconsGrid>
      </SectionIconsStyle>
    );
  }
}

export default SectionIcons;
