import React from 'react';

import {
  TaskAnimationContainer,
} from './styles/TaskAnimationStyles';

import TaskAnimationTile from './TaskAnimationTile';

class TaskAnimation extends React.PureComponent {
  render() {
    return (
      <TaskAnimationContainer>
        <TaskAnimationTile
          dotColor={'#FF704F'}
          taskTitle ={'Update speakers'}
          taskDate={'19 Aug at 11:30'}
        />
        <TaskAnimationTile
          dotColor={'#A070FF'}
          taskTitle ={'Send emails'}
          taskDate={'19 Aug at 17:30'}
        />
        <TaskAnimationTile
          dotColor={'#586FFA'}
          taskTitle ={'Call the designer'}
          taskDate={'20 Aug at 12:00'}
        />
        <TaskAnimationTile
          dotColor={'#66f7ff'}
          taskTitle ={'Create presentation'}
          taskDate={'22 Aug at 14:00'}
        />
      </TaskAnimationContainer>
    );
  }
}

export default TaskAnimation;
