import React from 'react';

import Object1 from '../images/3d/1.png';
import Object2 from '../images/3d/2.png';
import Object3 from '../images/3d/3.png';
import Object4 from '../images/3d/4.png';
import Object5 from '../images/3d/5.png';
import Object6 from '../images/3d/6.png';
import Object7 from '../images/3d/7.png';
import Object8 from '../images/3d/8.png';
import Object9 from '../images/3d/9.png';
import Object10 from '../images/3d/10.png';
import Object11 from '../images/3d/11.png';
import Object12 from '../images/3d/12.png';
import Object13 from '../images/3d/13.png';
import Object14 from '../images/3d/14.png';
import Object15 from '../images/3d/15.png';
import Object16 from '../images/3d/16.png';

import BackgroundObjectsStyle from './styles/BackgroundObjectsStyle';

import ObjectImage from './ObjectImage';

class BackgroundObjects extends React.PureComponent {
  
  render() {
    const {
      innerHeight,
      windowPosition,
      maxScroll,
      innerWidth,
      maxScrollCurrentTop
    } = this.props;
    
    return (
      <BackgroundObjectsStyle>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object1} posLeft={'4%'} posTop={0.25} scrollValue={3.5} zIndex={1} />
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object2} posLeft={'17.5%'} posTop={0.2} scrollValue={0.8} zIndex={2} fromBottom={true} />
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object3} posLeft={'32.5%'} posTop={0.15} scrollValue={4} zIndex={3} fromBottom={true} />
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object4} posLeft={'47.5%'} posTop={0.1} scrollValue={5} zIndex={4} fromBottom={true} />
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object5} posLeft={'12.5%'} posTop={0.35} scrollValue={1} zIndex={5}/>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object6} posLeft={'27.5%'} posTop={0.3} scrollValue={3.5} zIndex={6} />
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object7} posLeft={'42.5%'} posTop={0.25} scrollValue={2.6} zIndex={7}/>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object8} posLeft={'57.5%'} posTop={0.2} scrollValue={2} zIndex={8} fromBottom={true} />
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object9} posLeft={'22.5%'} posTop={0.45} scrollValue={1.9} zIndex={9} fromBottom={true}/>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object10} posLeft={'37.5%'} posTop={0.4} scrollValue={2.2} zIndex={10}/>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object11} posLeft={'52.5%'} posTop={0.35} scrollValue={3.8} zIndex={11}/>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object12} posLeft={'67.5%'} posTop={0.3} scrollValue={1.5} zIndex={12}/>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object13} posLeft={'32.5%'} posTop={0.55} scrollValue={0.5} zIndex={13} fromBottom={true} />    
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object14} posLeft={'47.5%'} posTop={0.5} scrollValue={0.6} zIndex={14}/>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object15} posLeft={'62.5%'} posTop={0.45} scrollValue={2.5} zIndex={15}/>
        <ObjectImage innerHeight={innerHeight} innerWidth={innerWidth} maxScrollCurrentTop={maxScrollCurrentTop} windowPosition={windowPosition} maxScroll={maxScroll} src={Object16} posLeft={'77.5%'} posTop={0.38}  scrollValue={2.2} zIndex={16}/>
      </BackgroundObjectsStyle>
    );
  }
}

export default BackgroundObjects;
