import styled from "styled-components";

export const MeetHioiBg = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 30vh;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  bottom: 0;
`;
