import styled from "styled-components";

export const BasicImgStyle = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  background-image: ${props => props.bgImg};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const GifImageStyle = styled.div`
  display:  ${ props => props.isBackground ? 'block' : 'inline-block' };
  position: ${ props => props.isBackground ? 'fixed' : 'relative' };
  width: 100%;
  vertical-align: middle;
  margin-bottom: 0;
  transition: 300ms linear;
  opacity: ${ props => props.isVisible ? '1' : '1' };

  /* 1024px */
    @media (min-width: 64em) {
      width: 60%;
      margin-bottom: 0;
      padding: 10rem;
    }
  `;

export const MeetHioiImageStyle = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: ${ props => props.isVisible ? '7rem' : '18rem' };
  transition: 600ms linear;
  opacity: ${ props => props.isVisible ? '1' : '0' };
`;


export const ObjectImageStyle = styled.div`
  display: block;
  position: absolute;
  width: 18%;
  float: left;
  opacity: 1;
  margin-bottom: 0;
  left: ${props => props.thisStyles.left};
  background-position: center;
  border-radius: 100rem;
  will-change: top;
  transform: translateZ(0);
  transition: top 500ms ease-out;
`;
