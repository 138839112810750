import React from 'react';

import { SmallHeaderTypo, BasicParagraphTypo } from '../commonStyles/typoTheme';
import { SectionSubscribeStyle } from '../commonStyles/SectionsStyle';
import {
  SubscribeParagraph,
  SubscribeHeader,
  SubscribeEmail
} from './styles/SectionSubscribeStyles';

import SubscribeEmailInputAndButton from './SubscribeEmailInputAndButton';

class SectionSubscribe extends React.PureComponent {
  render() {
    return (
      <SectionSubscribeStyle>
        <SubscribeHeader>
          <SmallHeaderTypo>
            Can't wait? Beta coming soon
          </SmallHeaderTypo>
        </SubscribeHeader>
        <SubscribeParagraph>
          <BasicParagraphTypo>
            HIOI Beta will be available in late 2020.<br />
            Meanwhile, you can contact us at contact@hioiapp.com
            or simply <a href={'https://www.facebook.com/hioiapp/'} target={'_blank'}>follow us</a>.
          </BasicParagraphTypo>
        </SubscribeParagraph>
      </SectionSubscribeStyle>
    );
  }
}

export default SectionSubscribe;
