import React from "react"

import { SmallestHeader, BasicParagraphTypo } from '../commonStyles/typoTheme';

import {
  IconText,
  IconHeader,
  IconBoxContainer,
  IconParagraph,
  IconImage
 } from './styles/IconsGridStyles';


class IconBox extends React.PureComponent {

  render() {

    const {
      imgSrc,
      iconColor,
      iconHeader,
      iconParagraph,
      isLeft
    } = this.props;

    return (
        <IconBoxContainer isLeft={isLeft}>
          <IconImage iconColor={iconColor}>
            <img src={imgSrc} />
          </IconImage>
          <IconText>
            <IconHeader>
              <SmallestHeader>
                {iconHeader}
              </SmallestHeader>
            </IconHeader>
            <IconParagraph>
              <BasicParagraphTypo>
                {iconParagraph}
              </BasicParagraphTypo>
            </IconParagraph>
          </IconText>
        </IconBoxContainer>
    )
  }
}

export default IconBox;
