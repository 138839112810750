import styled from "styled-components";


export const IconsGrid = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  float: left;

  /* 1024px */
    @media (min-width: 64em) {
      width: 100%;
      float: right;
      margin-top: 5rem;
    }

  /* 1366px */
    @media (min-width: 85.375em) {
      width: 60%;
      float: right;
      margin-top: 0;
    }
`;

export const IconBoxContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  float: left;
  margin-bottom: 3rem;

  /* 700px */
    @media (min-width: 43.750em) {
      width: calc(50% - 2rem);
      margin-right: ${ props => props.isLeft ? '4rem' : '0' };
      margin-bottom: 8rem;
      float: ${ props => props.isLeft ? 'left' : 'right' };
    }
`;

export const IconImage = styled.div`
  display: block;
  position: relative;
  width: 6rem;
  height: 6rem;
  padding: 1rem;
  background: ${props => props.iconColor};
  border-radius: 6rem;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
`;

export const IconText = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin: 6rem 0;
`;

export const IconHeader = styled.div`
  display: block;
  position: relative;
  width: 80%;
  margin: 3rem 0;
`;

export const IconParagraph = styled.div`
  display: block;
  position: relative;
  width: 100%;

  /* 1024px */
    @media (min-width: 64em) {
      width: 80%;
    }
`;
