import styled from "styled-components";

export const MeetHioiTextContainer = styled.div`
  display: block;
  position: relative;
  margin: auto;
  width: 100%;
  text-align: center;
  transition: 600ms linear;
  opacity: ${ props => props.isVisible ? '1' : '0' };

  /* 1024px */
    @media (min-width: 64em) {
      width: 60%;
    }

  /* 1700px */
    @media (min-width: 106.250em) {
      width: 40%;
    }
`;

export const MeetHioiHeader = styled.div`
  margin: 6rem 0;
  color: #f5f6fa;
`;

export const MeetHioiParagraph = styled.div`
  color: #dadff6;
`;


export const MeetHioiLine = styled.div`
  display: block;
  position: relative;
  margin: auto;
  width: 7rem;
  height: 4px;
  background: #ffcb55;
`;
