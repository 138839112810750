import React from 'react';


class IconNotification extends React.PureComponent {

  render() {

    return (
      <svg version="1.1" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22" width="26px" height="26px">
        <path fill ='#bdbfc6' d="M9,2.13A.5.5,0,0,0,9,2,1,1,0,0,0,7,2a.5.5,0,0,0,0,.13A4,4,0,0,0,4,6v6h8V6A4,4,0,0,0,9,2.13Z" transform="translate(0 7)"/>
        <path fill ='#bdbfc6' d="M8,15a2,2,0,0,0,2-2H6A2,2,0,0,0,8,15Z" transform="translate(0 7)"/>
      </svg>
      );
  }
}

export default IconNotification;
