export default [
  { id: 1,
    projectName: 'Landing page',
    color:'#ffbaaa',
    dotColor: '#ff7b5e',
    barStart: '0',
    barEnd: '50',
    event1Name:'Call the designer',
    event1Percentage:'10%',
    event1Date:'Friday, 22th Sept | 12:30',
    event1Location: 'Chiswick Business Park, London W4 5YG',
    event1Notification: 'Email me 30min before',
    event1Avatar1Letter:'A',
    event1Avatar1Color:'#ff8375',
    event1Avatar2Letter:'J',
    event1Avatar2Color:'#ffba75'
  },
  { id: 2,
    projectName: 'Ad campaign',
    color:'#9baaff',
    dotColor: '#6b81ff',
    barStart: '30',
    barEnd: '90',
    event1Name:'Send emails',
    event1Percentage:'30%',
    event1Date:'Sunday, 13th Oct | 17:25',
    event1Location: 'Bernard St, Bloomsbury, London WC1N 1LJ',
    event1Notification: 'Notify me 2h before',
    event1Avatar1Letter:'M',
    event1Avatar1Color:'#6691e8',
    event1Avatar2Letter:'K',
    event1Avatar2Color:'#66d4e8'
  },
  { id: 3,
    projectName: 'Social media',
    color:'#67e0a5',
    dotColor: '#05c669',
    barStart: '10',
    barEnd: '80',
    event1Name:'Update the feed',
    event1Percentage:'70%',
    event1Date:'Monday, 28th Oct | 19:00',
    event1Location: '21 Duke St Hill, London SE1 2SW',
    event1Notification: 'Email me 5h before',
    event1Avatar1Letter:'W',
    event1Avatar1Color:'#42ce8a',
    event1Avatar2Letter:'B',
    event1Avatar2Color:'#42cec2'
  }
];
