import styled from "styled-components";


export const SubscribeHeader = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
`;

export const SubscribeParagraph = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 5rem;
  text-align: center;
`;


export const SubscribeEmail = styled.div`
  display: block;
  position: relative;
  margin: auto;
  width: 100%;

  /* 1024px */
    @media (min-width: 64em) {
      width: 50rem;
    }
`;
