import React from 'react';

import { MediumHeaderTypo, BasicParagraphTypo } from '../commonStyles/typoTheme';

import { MeetHioiTextContainer, MeetHioiHeader, MeetHioiParagraph, MeetHioiLine } from './styles/MeetHioiTextStyles';

class MeetHioiText extends React.PureComponent {

  constructor() {
    super();
    this.updateBackground = this.updateBackground.bind(this);

    this.state = {
      isVisible: false,
      thisSelector: 0
    }
  }


  setSelector() {
    this.setState({
      thisSelector: Math.abs(this.props.refMaxScroll.current.getBoundingClientRect().top)
    });
  }

  componentDidMount() {
    this.updateBackground();
  }


  updateBackground() {

    const {
      windowPosition,
      refMaxScroll
    } = this.props;

    const {
      isVisible,
      thisSelector
    } = this.state;

    const payload = {
    };

    const checkSelector = parseInt(refMaxScroll.current.getBoundingClientRect().top  + document.documentElement.scrollTop);
    if (checkSelector !== thisSelector) payload.thisSelector = checkSelector;

    if (!isVisible && refMaxScroll &&  windowPosition >= (checkSelector * 0.65) ) {
      this.props.setBackground({
        background: '#3241CE',
        logoColor: '#4860E1'
      });
      payload.isVisible = true;
      this.setState(payload);
    } else if (isVisible && refMaxScroll &&  windowPosition <  (checkSelector * 0.65) ) {
      this.props.setBackground({
        background: '#FBFBFF',
        logoColor: '#FFFFFF'
      });
      payload.isVisible = false;
      this.setState(payload);
    }

  }


  componentDidUpdate() {
    this.updateBackground();
  }



  render() {

    const {
        isVisible
    } = this.state;

    const {
      refMaxScroll
    } = this.props;

    return (
        <MeetHioiTextContainer ref={refMaxScroll} isVisible={isVisible}>
          <MeetHioiLine>
          </MeetHioiLine>
          <MeetHioiHeader>
            <MediumHeaderTypo>
              Meet HIOI
            </MediumHeaderTypo>
          </MeetHioiHeader>
          <MeetHioiParagraph>
            <BasicParagraphTypo>
              Hioi is a new kind of task management platform. It allows to manage your projects and task in an easy visual way. Designed to make your work easier, more intuitive and faster, it allows you to plan, organize and track small meetings and huge projects. Set deadlines and collaborate with your team. All in one tool.
            </BasicParagraphTypo>
          </MeetHioiParagraph>
        </MeetHioiTextContainer>
    );
  }
}

export default MeetHioiText;
