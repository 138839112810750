import React from 'react';
import View from '../images/view.png';

import { MeetHioiImageStyle } from '../commonStyles/ImagesStyle';

class MeetHioiImage extends React.PureComponent {

  constructor() {
    super();
    this.selector = React.createRef();

    this.state = {
      isVisible: false,
      thisSelector: 0
    }
  }

  componentDidMount() {
    this.setState({
      thisSelector: Math.abs(this.selector.current.getBoundingClientRect().top)
    });
  }


  componentDidUpdate() {

    const {
      windowPosition
    } = this.props;

    const {
      isVisible,
      thisSelector
    } = this.state;

    if (!thisSelector) return;

    if (!isVisible && this.selector &&  windowPosition >  (thisSelector * 0.5) ) {
      this.setState({
          isVisible: true
        });
    } else if (isVisible && this.selector &&  windowPosition <  (thisSelector * 0.5) ) {
      this.setState({
        isVisible: false
      });
    }

  }



  render() {

      const {
        isVisible
      } = this.state;


  return (
    <MeetHioiImageStyle ref={this.selector} isVisible={isVisible}>
      <img src={View} alt="Logo" width='100%' />
    </MeetHioiImageStyle>
  );
}
}

export default MeetHioiImage;
