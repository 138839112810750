function getInitialValue(props) {
  
  let thisInitialValue = props.posTop * props.maxScroll;
  thisInitialValue = props.fromBottom ? -thisInitialValue : thisInitialValue;
    
  return parseInt(thisInitialValue);
  
}

export default getInitialValue;
