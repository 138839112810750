import styled from "styled-components";


export const FooterColumn = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: ${ props => props.isLast ? 0 : '5rem' };
  line-height: 1.8;
  float: left;
  padding-bottom: ${ props => props.isLast ? 0 : '3rem' };
  border-bottom: ${ props => props.isLast ? 'none' : '1px solid #3241CE' };

  /* 1024px */
    @media (min-width: 64em) {
      width: 50%;
      margin-bottom: 0;
      float: ${ props => props.isLast ? 'right' : 'left' };
      border-bottom: none;
      padding-bottom: 0;
    }
`;


export const FooterLink = styled.a`
  display: block;
  width: 50%;
  position: relative;
  float: left;
  text-align: center;
  margin: 0 25% 2rem;
  color: #fff;
  text-decoration: none;
  border-bottom: 2px solid #ffcbc7;

  /* 1024px */
    @media (min-width: 64em) {
      display: inline-block;
      float: ${ props => props.isLast ? 'right' : 'left' };
      text-align: left;
      width: fit-content;
      margin: 0 2rem;
      width: fit-content;
    }
`;
