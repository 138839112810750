import React from 'react';

import { SmallHeaderTypo, CardSubtitleTypo, BasicParagraphTypo, TipTypo } from '../commonStyles/typoTheme';

import { FeatureDescriptionTextContainer, FeatureDescriptionTextSubHeader, FeatureDescriptionTextHeader, FeatureDescriptionTextParagraph, FeatureDescriptionTextTip } from './styles/FeatureDescriptionTextStyle';

class FeatureDescriptionText extends React.PureComponent {

  render() {

    const {
      isIcons,
      subheader,
      header,
      paragraph,
      tip,
      hasTip
    } = this.props;

    return (
        <FeatureDescriptionTextContainer isIcons={isIcons}>
          <FeatureDescriptionTextSubHeader>
            <CardSubtitleTypo>
              {subheader}
            </CardSubtitleTypo>
          </FeatureDescriptionTextSubHeader>
          <FeatureDescriptionTextHeader>
            <SmallHeaderTypo>
              {header}
            </SmallHeaderTypo>
          </FeatureDescriptionTextHeader>
          <FeatureDescriptionTextParagraph>
            <BasicParagraphTypo>
              {paragraph}
            </BasicParagraphTypo>
          </FeatureDescriptionTextParagraph>
          <FeatureDescriptionTextTip hasTip={hasTip}>
            <TipTypo>
              TIP: {tip}
            </TipTypo>
          </FeatureDescriptionTextTip>
        </FeatureDescriptionTextContainer>
    );
  }
}

export default FeatureDescriptionText;
