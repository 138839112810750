import React from 'react';

import { SmallSubtitlesTypo, SmallSubtitlesTypoP, LargeHeaderTypo } from '../commonStyles/typoTheme';
import { SectionHeaderStyle } from '../commonStyles/SectionsStyle';

import { HeaderContainer, HeaderLine, HeaderMainHeader, HeaderWhatIs, HeaderEmail } from './styles/HeaderStyles';

import SubscribeEmailInputAndButton from './SubscribeEmailInputAndButton';

class SectionHeader extends React.PureComponent {
  render() {
    return (
      <SectionHeaderStyle>
        <HeaderContainer>
          <HeaderLine>
          </HeaderLine>
          <HeaderMainHeader>
            <LargeHeaderTypo>
              Supercool project management tool is coming soon.
            </LargeHeaderTypo>
          </HeaderMainHeader>
          <HeaderWhatIs>
            <SmallSubtitlesTypoP>
              Can't wait?
            </SmallSubtitlesTypoP>
            <SmallSubtitlesTypo>
              Beta coming soon!
            </SmallSubtitlesTypo>
          </HeaderWhatIs>
        </HeaderContainer>
      </SectionHeaderStyle>
    );
  }
}

export default SectionHeader;
