import styled from "styled-components";

export default styled.div`
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

  /* 1024px */
  @media (min-width: 64em) {
    width: 70%;
    left: 15%;
  }
`;
