import React from 'react';

import IndexComponent  from '../components/IndexComponent';
import GlobalStyle from '../components/layoutCss';



class IndexPage extends React.PureComponent {
  render() {
    return (
      <>
        <GlobalStyle />
        <IndexComponent />
      </>
    );
  }
}

export default IndexPage;
