import React from 'react';

import { BasicParagraphTypo, SmallSubtitlesTypo, TimelineAnimAvatarTypo } from '../commonStyles/typoTheme';
import {
  TimelineEventInfo,
  TimelineEventInfoTop,
  TimelineEventInfoDate,
  TimelineEventInfoNotification,
  TimelineEventInfoPeople,
  TimelineEventInfoAvatar,
  TimelineEventInfoAvatarBg,
  TimelineEventInfoBottom,
  TimelineEventInfoAvatarLetter
} from './styles/TimelineAnimationStyles';

import IconNotification from './IconNotification';
import IconLocation from './IconLocation';

class TimelineEventInfoPopup extends React.PureComponent {
  
  render() {
    
    const {
      dotHovered,
      dotColor,
      event1Name,
      event1Date,
      event1Location,
      event1Percentage,
      event1Notification,
      avatar1Color,
      avatar1Letter,
      avatar2Color,
      avatar2Letter,
    } = this.props;

    return (
      <TimelineEventInfo dotHovered={dotHovered} event1Percentage={event1Percentage}>
        <TimelineEventInfoTop dotColor={dotColor}>
          <BasicParagraphTypo>
            {event1Name}
          </BasicParagraphTypo>
          <SmallSubtitlesTypo>
            {event1Date}
          </SmallSubtitlesTypo>
        </TimelineEventInfoTop>
        <TimelineEventInfoBottom>
          <TimelineEventInfoDate>
            <BasicParagraphTypo>
              <IconLocation />{event1Location}
            </BasicParagraphTypo>
          </TimelineEventInfoDate>
          <TimelineEventInfoNotification>
            <BasicParagraphTypo>
              <IconNotification />{event1Notification}
            </BasicParagraphTypo>
          </TimelineEventInfoNotification>
          <TimelineEventInfoPeople>
            <TimelineEventInfoAvatar>
              <TimelineEventInfoAvatarBg avatarColor={avatar1Color}>
              </TimelineEventInfoAvatarBg>
              <TimelineEventInfoAvatarLetter avatarColor={avatar1Color}>
                <TimelineAnimAvatarTypo>
                  {avatar1Letter}
                </TimelineAnimAvatarTypo>
              </TimelineEventInfoAvatarLetter>
            </TimelineEventInfoAvatar>
            <TimelineEventInfoAvatar>
              <TimelineEventInfoAvatarBg avatarColor={avatar2Color}>
              </TimelineEventInfoAvatarBg>
              <TimelineEventInfoAvatarLetter avatarColor={avatar2Color}>
                <TimelineAnimAvatarTypo>
                  {avatar2Letter}
                </TimelineAnimAvatarTypo>
              </TimelineEventInfoAvatarLetter>
            </TimelineEventInfoAvatar>
            <TimelineEventInfoAvatar>
            </TimelineEventInfoAvatar>
          </TimelineEventInfoPeople>
        </TimelineEventInfoBottom>
      </TimelineEventInfo>
    );
  }
}

export default TimelineEventInfoPopup;
