import React from 'react';

import { SectionFeatureStyle } from '../commonStyles/SectionsStyle';

import FeatureDescriptionText from './FeatureDescriptionText';
import TaskAnimation from './TaskAnimation';

class SectionFeatureTaskAnim extends React.PureComponent {
  render() {
    const {
      subheader,
      header,
      paragraph,
      isIcons,
      tip
    } = this.props;

    return (
      <SectionFeatureStyle isIcons={isIcons}>
        <TaskAnimation/>
        <FeatureDescriptionText
          isIcons={false}
          subheader={subheader}
          header={header}
          paragraph={paragraph}
          hasTip={true}
          tip={tip}
        />
      </SectionFeatureStyle>
    );
  }
}

export default SectionFeatureTaskAnim;
