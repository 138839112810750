import React from 'react';

import {
  SubscribeEmail,
  SubscribeInputField,
  SubscribeButton
} from './styles/SubscribeInputStyles';

class SubscribeEmailInputAndButton extends React.PureComponent {
  render() {
    return (
          <SubscribeEmail
            action={'https://hioiapp.us16.list-manage.com/subscribe/post?u=e6423eec0aea01b74025ba8a8&amp;id=41bb7f5358'}
            method={'post'}
            target={'_blank'}
          >
            <SubscribeInputField
              hasBackground={this.props.hasBackground}
              placeholder={'youremail@mail.com'}
              type={'email'}
              name={'EMAIL' }
            >
            </SubscribeInputField>
            <SubscribeButton hasLargeButton={this.props.hasLargeButton}>
              sign up for beta
            </SubscribeButton>
          </SubscribeEmail>
    );
  }
}

export default SubscribeEmailInputAndButton;
